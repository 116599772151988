import { CreateCommentDocument } from '~/api/graphql/generated'
import { reset, FormKitNode } from '@formkit/core'

const getErrorMessagesFrom = (list: any) =>
  list
    ?.filter(Boolean)
    ?.map((item: any) => item.message)
    .filter(Boolean) || []

export const useCommentForm = () => {
  const { t } = useI18n()
  const store = useCommentFormStore()

  const { mutate, error } = useMutation(CreateCommentDocument)

  const postId = computed(() => store.postId)
  const parent = computed(() => ({
    default: store.parent.default,
    current: store.parent.current,
    id: store.parent.id,
  }))
  const errors = ref<string[]>([])
  const form = ref<FormKitNode | undefined>()
  const showSuccessMessage = ref(false)

  const onSubmit = async (formData: { name: string; email: string; body: string }) => {
    if (!postId.value) {
      errors.value = [t('form.errors.generalError')]
      console.warn('no entity id provided')
      return
    }

    showSuccessMessage.value = false
    await new Promise((resolve) => setTimeout(resolve, 3000))

    const data = {
      ...formData,
      entityId: postId.value,
      parentId: parent.value.id,
    }
    const result = await mutate(data)

    if (
      result &&
      result.data &&
      result.data.createComment?.errors?.length === 0 &&
      result.data.createComment?.violations?.length === 0
    ) {
      showSuccessMessage.value = true
    } else {
      errors.value = [
        t('form.errors.generalError'),
        ...getErrorMessagesFrom(result?.data?.createComment?.errors),
        ...getErrorMessagesFrom(result?.data?.createComment?.violations),
      ]
    }
  }

  const onSuccessAcknowledgement = () => {
    showSuccessMessage.value = false
    store.setParent(null)
    reset(form.value || '')
  }

  watch(
    () => error.value,
    () => {
      if (error.value) {
        errors.value = [error.value.message]
      }
    },
  )

  return {
    postId,
    parent,
    form,
    showSuccessMessage,
    errors,
    onSubmit,
    onSuccessAcknowledgement,
    setPostId: store.setPostId,
    setParent: store.setParent,
  }
}
